<template>
  <img
    v-if="isNotNull()"
    class="base-image-input"
    :src="intArrToString(imageData)"
  />
</template>

<script>
export default {
  name: "announcementimage",
  props: {
    imageData: {
      type: Array,
      required: false,
    },
  },
  methods: {
    intArrToString() {
      return new Uint8Array(this.imageData).reduce(
        (data, byte) => data + String.fromCharCode(byte),
        ""
      );
    },
    isNotNull() {
      return this.intArrToString(this.imageData) !== "null";
    },
  },
};
</script>

<style scoped>
.base-image-input {
  display: block;
  width: 200px;
  height: 200px;
  cursor: pointer;
  background-size: cover;
  background-position: center center;
}
</style>