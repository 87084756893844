<template>
  <div>
    <v-card class="mb-5">
      <v-row no-gutters>
        <v-col cols="12">
          <!--
          <v-parallax
            height="250"
            dark
            :src="require('@/assets/COBISOFT-Partnerportal-Header.jpg')"
            align-center
          >
            <v-container align="left">
              <span class="text-style background-black">Welcome to the COBISOFT</span>
              <br>
              <span class="text-style background-black">Partner Portal</span>
            </v-container>
          </v-parallax>
          -->

          <v-img
            height="350"
            max-width="100hw"
            :src="require('@/assets/COBISOFT-Partnerportal-Header.jpg')"
            position="top center"
          >
            <div
              style="
                position: relative;
                top: 50%;
                -ms-transform: translateY(-50%);
                transform: translateY(-50%);
                margin-left: 5%;
              "
            >
              <span class="text-style background-black mx-1"
                >Welcome to the COBISOFT</span
              >
              <br />
              <span class="text-style background-black mx-1"
                >Partner Portal</span
              >
            </div>
          </v-img>
        </v-col>
      </v-row>
    </v-card>

    <v-row class="mt-5 mb-6">
      <v-col cols="4">
        <v-card class="ml-8" style="height: 100%">
          <v-row v-if="getAnnouncement.image" :align="'center'" justify="center">
            <announcement-image :imageData="getAnnouncement.image.data">
            </announcement-image>
          </v-row>
          <v-card-title> {{ getAnnouncement.announcementtitle }} </v-card-title>
          <v-card-text v-html="compiledMarkdown"> </v-card-text>
          <v-container fluid class="pt-0" style="align-items: start">
            <!--             <v-img
              class="pt-0"
              :cover="true"
              max-width
              max-height
              dark
              :src="require('@/assets/Announcement.png')"
            ></v-img> -->
          </v-container>
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-card class="mx-4">
          <v-card-title>
            <h2>Revenue</h2>
          </v-card-title>
          <v-card-text>
            <canvas id="barchart_container" width="200" height="200"></canvas>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-card class="mr-8">
          <v-card-title>
            <h2>Licenses sold</h2>
          </v-card-title>
          <v-card-text>
            <canvas id="piechart_container" width="200" height="200"></canvas>
          </v-card-text>
        </v-card>
      </v-col>
      <!--
      <v-col
        cols="3"
        class="mr-6"
      >
        <v-card>
          <v-card-title>
            <h2>License Renewals</h2>
          </v-card-title>
          <v-card-text>

          </v-card-text>
        </v-card>
      </v-col>
      -->
    </v-row>
  </div>
</template>

<script>
import {
  Chart,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle,
} from "chart.js";
import { mapGetters, mapActions } from "vuex";
import { marked } from "marked";
import announcementImage from "../../components/admin/announcements/view/announcementimage.vue";

export default {
  name: "dashboard",
  components: {
    announcementImage,
  },
  created() {
    /*
    let googleapi = document.createElement('script')
    googleapi.setAttribute('src', 'https://apis.google.com/js/platform.js')
    document.head.appendChild(googleapi)

    let twitter = document.createElement('script')
    twitter.setAttribute('src', 'https://platform.twitter.com/widgets.js')
    twitter.setAttribute('charset', 'utf-8')
    document.head.appendChild(twitter)
    */
  },
  computed: {
    ...mapGetters({
      getAnnouncement: "getAnnouncement",
      templateById: "templateById",
      applicationById: "applicationById",
      allCustomerlicenses: "allCustomerlicenses",
      activeUniqueCustomerlicenses: "activeUniqueCustomerlicenses",
      activeUniqueCustomerlicensesByMonth:
        "activeUniqueCustomerlicensesByMonth",
    }),
    compiledMarkdown() {
      return marked(this.getAnnouncement.announcementtext);
    },
  },
  methods: {
    ...mapActions({
      fetchAnnouncementAsync: "fetchAnnouncementAsync",
      fetchSubcustomersAsync: "fetchSubcustomersAsync",
      fetchCustomerlicensesAsync: "fetchCustomerlicensesAsync",
      fetchApplicationsAsync: "fetchApplicationsAsync",
      fetchTemplatesAsync: "fetchTemplatesAsync",
    }),
  },
  async mounted() {
    await this.fetchSubcustomersAsync();
    await this.fetchCustomerlicensesAsync();
    await this.fetchApplicationsAsync();
    await this.fetchTemplatesAsync();
    await this.fetchAnnouncementAsync();

    var sold_tmp = [];
    this.activeUniqueCustomerlicenses.forEach((cl) => {
      var template = this.templateById(cl.templateid);
      var application = this.applicationById(template.applicationid);
      var tmp = sold_tmp.find(
        (x) => x.applicationid == application.applicationid
      );
      if (tmp) {
        tmp.count += Number(cl.amount);
      } else {
        sold_tmp.push({
          applicationid: application.applicationid,
          applicationname: application.name,
          count: Number(cl.amount),
        });
      }
    });

    var sold_labels = [];
    var sold_data = [];

    sold_tmp.forEach((t) => {
      sold_labels.push(t.applicationname);
      sold_data.push(t.count);
    });

    Chart.register(
      ArcElement,
      LineElement,
      BarElement,
      PointElement,
      BarController,
      BubbleController,
      DoughnutController,
      LineController,
      PieController,
      PolarAreaController,
      RadarController,
      ScatterController,
      CategoryScale,
      LinearScale,
      LogarithmicScale,
      RadialLinearScale,
      TimeScale,
      TimeSeriesScale,
      Decimation,
      Filler,
      Legend,
      Title,
      Tooltip,
      SubTitle
    );

    var ctx = document.getElementById("piechart_container").getContext("2d");
    var chart1 = new Chart(ctx, {
      type: "pie",
      data: {
        labels: sold_labels,
        datasets: [
          {
            label: "Licenses sold by application",
            data: sold_data,
            backgroundColor: [
              "rgba(140, 137, 123, 0.6)", //8c897b
              "rgba(89, 84, 69, 0.6)", // 595445
              "rgba(36, 36, 36, 0.6)", // 242424
              "rgba(0, 0, 0, 0.6)", // 000000
              "rgba(186, 189, 191, 0.6)", // babdbf
            ],
            borderColor: [
              "rgba(140, 137, 123, 1.0)", //8c897b
              "rgba(89, 84, 69, 1.0)", // 595445
              "rgba(36, 36, 36, 1.0)", // 242424
              "rgba(0, 0, 0, 1.0)", // 000000
              "rgba(186, 189, 191, 1.0)", // babdbf
            ],
            borderWidth: 1,
          },
        ],
      },
    });
    var barchart_data = [];
    var barchart_labels = [];
    var date = new Date();
    var months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Okt",
      "Nov",
      "Dez",
    ];
    for (let i = 0; i < 6; i++) {
      var monthName = months[date.getMonth()];
      var firstDay = new Date(
        date.getFullYear(),
        date.getMonth(),
        1,
        0,
        0,
        0,
        0
      );
      var lastDay = new Date(
        date.getFullYear(),
        date.getMonth() + 1,
        0,
        23,
        59,
        59,
        999
      );
      var cost = 0;
      var licenses = this.activeUniqueCustomerlicensesByMonth(date);
      licenses.forEach((license) => {
        if (license.subscription) {
          cost += Number(license.amount) * parseFloat(license.finalprice);
        } else {
          var issued = Number(license.issued);
          if (issued >= firstDay.getTime() && issued <= lastDay.getTime()) {
            cost += Number(license.amount) * parseFloat(license.finalprice);
          }
        }
      });
      barchart_labels.push(monthName);
      barchart_data.push(cost);
      date.setMonth(date.getMonth() - 1);
    }
    barchart_labels.reverse();
    barchart_data.reverse();
    ctx = document.getElementById("barchart_container").getContext("2d");
    var chart2 = new Chart(ctx, {
      type: "bar",
      data: {
        labels: barchart_labels,
        datasets: [
          {
            label: "Revenue",
            data: barchart_data,
            backgroundColor: [
              "rgba(179, 22, 22, 0.1)", //b31616
              "rgba(179, 22, 22, 0.1)", //b31616
              "rgba(179, 22, 22, 0.1)", //b31616
              "rgba(179, 22, 22, 0.1)", //b31616
              "rgba(179, 22, 22, 0.1)", //b31616
              "rgba(179, 22, 22, 0.1)", //b31616
            ],
            borderColor: [
              "rgba(179, 22, 22, 1.0)", //b31616
              "rgba(179, 22, 22, 1.0)", //b31616
              "rgba(179, 22, 22, 1.0)", //b31616
              "rgba(179, 22, 22, 1.0)", //b31616
              "rgba(179, 22, 22, 1.0)", //b31616
              "rgba(179, 22, 22, 1.0)", //b31616
            ],
            borderWidth: 1,
          },
        ],
      },
    });
  },
};
</script>

<style scoped>
.background-black {
  background-color: black;
}
.text-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding-top: 0px;
  padding-left: 0px;
}
.text-style {
  font-size: 3.75rem;
  font-weight: 750;
  font-family: Calibri !important;
  color: #ffffff;
}
</style>